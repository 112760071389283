<template>
    <step-wrapper
        :index="index"
        :active="active"
        :valid="valid"
        :step="name"
        title="How much do you want to spend &amp; when do you want to spend it?"
        :help-link="helpLink"
        description="
            Enter the amount you want to spend and how/when you
            want to spend it below:
        ">
        <complete v-if="!active" />
        <template v-else>
            <div class="budgeting-title mt-4">
                BUDGETING TYPE
            </div>
            <div class="budgeting-selector">
                <div
                    class="budgeting-select"
                    :class="{
                        'active': isDailySchedule
                    }"
                    @click="chooseSchedule('daily')">
                    Daily
                </div>
                <div
                    class="budgeting-select"
                    :class="{
                        'active': isLifetimeSchedule
                    }"
                    @click="chooseSchedule('lifetime')">
                    Lifetime
                </div>
            </div>
            <div class="budgeting-title double-title mt-4 mb-2">
                <div>SCHEDULE</div>
                <div class="budgeting-timezone">
                    <div>TIMEZONE</div>
                    <div class="timezone-details">
                        <span class="timezone-text">{{ timeDetails }}</span>
                        <styled-tooltip
                            close-delay="1500"
                            position="top">
                            <template #content>
                                All start and end times set below will be synchronized with this timezone even if the ad account is set to the incorrect timezone.
                                This will ensure that your ads will start when you expect them to consistently across all channels
                            </template>
                            <icon
                                name="question-circle"
                                color="#909FA8"
                                size="16" />
                        </styled-tooltip>
                    </div>
                </div>
            </div>
            <div class="schedule-wrapper">
                <div class="mb-2 flex gray-text">
                    <div class="mr-1">
                        START
                    </div>
                    <div
                        v-if="isDailySchedule"
                        class="budgeting-selector mt-4">
                        <styled-radio
                            class="mr-4"
                            :value="startImmediatelyChecked"
                            :input-value="true"
                            label="Start Immediately"
                            name="startImmediately"
                            dot-style
                            inline
                            @input="checkedStartImmediately(true)" />
                        <styled-radio
                            :value="startImmediatelyChecked"
                            :input-value="false"
                            label="Schedule Start Date"
                            name="startImmediately"
                            dot-style
                            inline
                            @input="checkedStartImmediately(false)" />
                    </div>
                </div>
                <div
                    v-if="!startImmediatelyChecked || isLifetimeSchedule"
                    class="time">
                    <div class="time-field">
                        <v-text-field
                            v-model="storeStartDateTime"
                            type="datetime-local"
                            class="styled-field"
                            :rules="startDateRules" />
                    </div>
                </div>
                <div class="mt-4">
                    <div v-if="isDailySchedule">
                        <div class="mt-4 mb-2 flex gray-text">
                            <div class="mr-3">
                                END
                            </div>
                            <div class="budgeting-selector mt-4">
                                <styled-radio
                                    class="mr-3"
                                    :value="endDateChecked"
                                    :input-value="false"
                                    label="Run Indefinitely"
                                    name="checkedUseEndDateTime"
                                    dot-style
                                    inline
                                    @input="checkedUseEndDateTime(false)" />
                                <styled-radio
                                    class="ml-3"
                                    :value="endDateChecked"
                                    :input-value="true"
                                    label="Schedule End Date"
                                    name="checkedUseEndDateTime"
                                    dot-style
                                    inline
                                    @input="checkedUseEndDateTime(true)" />
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="endDateChecked || isLifetimeSchedule"
                        class="w-100">
                        <div
                            v-if="isLifetimeSchedule"
                            class="budgeting-title mb-1">
                            END
                        </div>
                        <div class="time">
                            <div class="time-field">
                                <v-text-field
                                    v-model="storeEndDateTime"
                                    type="datetime-local"
                                    class="styled-field"
                                    :error-messages="endDateErrors" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="budgeting-title mt-4 mb-2">
                BUDGET CALCULATION
            </div>

            <div
                class="budgeting-wrapper"
                :class="{daily: isDailySchedule}">
                <div class="calc">
                    <div class="calc__head">
                        CHANNEL
                    </div>
                    <div class="calc__level">
                        BUDGET LEVEL
                    </div>
                    <div class="calc__spend">
                        SPEND
                    </div>
                </div>
                <div
                    v-for="(platform, i) in platforms"
                    :key="i">
                    <budgeting-selector :platform="platform" />
                </div>
                <div
                    v-if="isDailySchedule"
                    class="estimation-footer">
                    <div class="estimation-wrapper">
                        <div class="estimation-text">
                            Est. Monthly Total
                        </div>
                        <div class="estimation-main">
                            ${{ totalEstimatedMonthlyBudget }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="button-sad-holder">
                <styled-button
                    small
                    :disabled="!valid"
                    @click="goToNextStep">
                    <span>Continue</span>
                </styled-button>
            </div>
        </template>
    </step-wrapper>
</template>

<script>
import StepWrapper from '../../components/StepWrapper';
import StyledButton from '@/components/globals/StyledButton';
import Icon from '@/components/globals/Icon';
import StyledTooltip from '@/components/globals/StyledTooltip';
import StyledRadio from '@/components/globals/StyledRadio';
import BudgetingSelector from './BudgetingSelector';
import Complete from './Complete';
import { requiredRules } from '@/helpers/validationRules';
import stepProps from '../stepProps';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    name: 'Budgeting',
    components: {
        StyledButton,
        Icon,
        StyledTooltip,
        StyledRadio,
        BudgetingSelector,
        StepWrapper,
        Complete,
    },
    props: stepProps,
    data() {
        return {
            requiredRules,
            buttonDisabled: false,
            stepCompleted: true,
            endDateChecked: false,
            timeDetails: '',
            checkTime: null,
            startImmediatelyChecked: false,
            helpLink: 'https://support.buyerbridge.com/knowledge/how-to-ad-launcher#preset-budget'
        };
    },
    computed: {
        ...mapGetters('adDeployment', [
            'totalEstimatedMonthlyBudget'
        ]),
        ...mapState({
            budget: state => state.adDeployment.budget,
            schedule: state => state.adDeployment.budget.schedule,
            platforms: state => state.adDeployment.platforms,
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        isDailySchedule() {
            return this.schedule === 'daily';
        },
        isLifetimeSchedule() {
            return this.schedule === 'lifetime';
        },
        storeStartDateTime: {
            get() {
                return this.$store.state.adDeployment.budget.start_date_time;
            },
            set(newStart) {
                this.$store.commit('adDeployment/updateStartDateTime', newStart);
            }
        },
        storeEndDateTime: {
            get() {
                return this.$store.state.adDeployment.budget.end_date_time;
            },
            set(newEnd) {
                this.$store.commit('adDeployment/updateEndDateTime', newEnd);
            }
        },
        startDateRules() {
            if (this.isLifetimeSchedule) {
                return requiredRules;
            }

            if (this.isDailySchedule && !this.startImmediatelyChecked) {
                return requiredRules;
            }

            return [];
        },
        endDateErrors() {
            if (!(this.isLifetimeSchedule || (this.isDailySchedule && this.endDateChecked))) {
                return [];
            }

            if (!this.storeEndDateTime) {
                return ['End date is required'];
            }

            if (new Date(this.storeStartDateTime) >= new Date(this.storeEndDateTime)) {
                return ['End date must be after start date'];
            }

            return [];
        }
    },
    created() {
        this.setDefaults();
    },
    mounted() {
        this.setTimeDetails();
        this.checkTime = setInterval(() => {
            this.setTimeDetails();
        }, 60000);
    },
    destroyed() {
        clearInterval(this.checkTime);
    },
    methods: {
        ...mapActions({
            goToNextStep: 'adDeployment/goToNextStep'
        }),
        setDefaults(schedule) {
            if (schedule === 'daily') {
                this.storeStartDateTime = null;
                this.storeEndDateTime = null;
            } else if (schedule === 'lifetime') {
                this.storeStartDateTime = this.getDefaultStartDate();
                this.storeEndDateTime = this.getDefaultEndDate();
            }

            this.endDateChecked = Boolean(this.isDailySchedule && this.storeEndDateTime);
            this.startImmediatelyChecked = Boolean(this.isDailySchedule && this.storeStartDateTime === null);
        },
        getDefaultStartDate() {
            return new Date().toISOString().slice(0, 16);
        },
        getDefaultEndDate() {
            const futureDate = new Date();
            futureDate.setDate(futureDate.getDate() + 30);

            return futureDate.toISOString().slice(0, 16);
        },
        setTimeDetails() {
            const timezoneName = this.currentDealer.timezone.display_name.replace(/\s/g, '_');
            const dealerTimezone = new Date().toLocaleTimeString('en-US', {
                timeZone: timezoneName,
                timeZoneName: 'shortOffset'
            }).split(' ')[2];
            const dealerTime = new Date().toLocaleTimeString('en-US', {
                timeZone: timezoneName,
                hour: 'numeric',
                minute: 'numeric'
            });

            this.timeDetails = `${this.currentDealer.timezone.display_name}, (${dealerTimezone}), ${dealerTime}`;
        },
        chooseSchedule(schedule) {
            this.$store.commit('adDeployment/setBudgetSchedule', schedule);
            this.setDefaults(schedule);
        },
        checkedUseEndDateTime(event) {
            this.endDateChecked = event;
            this.storeEndDateTime = event ? this.getDefaultEndDate() : null;
        },
        checkedStartImmediately(event) {
            this.startImmediatelyChecked = event;
            this.storeStartDateTime = event ? null : this.getDefaultStartDate();
        }
    }
};
</script>
<style lang="scss" scoped>
.budgeting-title{
    text-transform: uppercase;
    color: #7C8187;
    font-size: 12px;
}
.budgeting-selector{
    display: flex;
    margin-top: 15px;
    margin-bottom: 15px;
    align-items: flex-start;
}
.budgeting-select{
    display: inline-block;
    margin-left: 30px;
    margin-right: 30px;
    position: relative;
    cursor: pointer;
    &:after{
        content:'';
        position: absolute;
        width: 20px;
        height: 20px;
        background: white;
        border-radius: 50%;
        border: 2px solid #555C63;
        top: 0;
        right: calc(100% + 10px);
    }
    &.active{
        &:after{
            border: 2px solid #00A3EA;
        }
        &:before{
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            background: #00A3EA;
            border-radius: 50%;
            top: 5px;
            right: calc(100% + 15px);
            z-index: 2;
        }
    }
}

.schedule-wrapper{
    background: white;
    border: 1px solid #DFDFE0;
    border-radius: 5px;
    padding:20px;
    max-width: 75%;
}
.budgeting-wrapper{
    position: relative;
    background: white;
    border: 1px solid #DFDFE0;
    border-radius: 5px;
    padding:20px;
    display: inline-flex;
    padding: 0;
    &.daily {
        padding-bottom: 55px;
    }
}
.w-100 {
    width: 100%;
}
.flex {
    display: flex;
}
.gray-text {
    color: #7C8187;
    font-size: 12px;
}
.calc{
    color: #7C8187;
    font-size: 12px;
    // padding-bottom: 30px;
    text-align: center;
    min-width: 185px;
    &__head{
        padding: 0 40px;
        height:60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #DFDFE0;
    }
    &__level{
        padding: 30px 40px;
    }
    &__spend{
        color: #03A2EA;
        font-weight: 600;
        padding: 10px 40px;
    }
}
.estimation-footer {
    position: absolute;
    left:0;
    bottom:0;
    right:0;
    background: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius:5px;
    border: 1px solid #DFDFE0;
    width: 100%;
    padding:15px 65px;
    text-align: right;
}
.estimation-wrapper {
    display: inline-flex;
    align-items: center;
}
.estimation-text {
    color: #7C8187;
    font-size: 12px;
    margin-right: 15px;
    padding-top: 2px;
}
.estimation-main {
    font-size: 16px;
}
</style>

<style>
.budgeting-wrapper .time input::placeholder{
    color: #4e555b;
}
.time-field input{
    padding-right: 10px !important;
}
.double-title {
    display: flex;
    justify-content: space-between;
    max-width: 75%;
}
.budgeting-timezone {
    display: flex;
}
.timezone-details {
    margin-left: 10px;
    text-transform: none;
    color: #4e555b;
    display: flex;
}
.timezone-text {
    margin-right:10px;
}
</style>
