<template>
    <div class="calc">
        <div class="calc-top">
            <div class="calc__head">
                <icon
                    :name="platform === 'facebook' ? 'meta' : platform"
                    color="#8F9EA6"
                    size="25" />
            </div>
            <div class="calc__level">
                <v-select
                    v-if="selectableLevels.length > 1"
                    v-model="selectedLevel"
                    return-object
                    :items="selectableLevels"
                    item-text="ui_name"
                    item-value="value"
                    :rules="requiredRules"
                    class="styled-field"
                    @change="updateLevelForPlatform" />
                <span
                    v-else
                    class="snapchat-squad">{{ selectedLevel.ui_name }} (fixed)</span>
            </div>
            <div class="calc__spend">
                <div class="spend-wrapper">
                    <span
                        v-if="schedule === 'daily'"
                        class="spend-text">Daily</span>
                    <span
                        v-else
                        class="spend-text">Lifetime</span>
                    <div class="spend-input">
                        <v-text-field
                            v-model="platformBudgetInStore"
                            pattern="[0-9]"
                            required
                            prefix="$"
                            :error-messages="budgetErrors"
                            class="styled-field dollar-field"
                            type="number" />
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="schedule === 'daily'"
            class="estimation">
            <div class="estimation-text">
                Est. Monthly
            </div>
            <div
                v-if="platformBudgetInStore"
                class="estimation-main">
                <span class="estimation-dollar">$</span>{{ platformBudgetInStore * 30 }}
            </div>
        </div>
        <div
            v-if="showBidCap"
            class="calc__footer">
            <div class="footer-spend-wrapper">
                <div class="footer-cpv-text estimation-cpv">
                    Maximum CPV
                </div>
                <div class="spend-input">
                    <v-text-field
                        v-model="bidCapInStore"
                        pattern="[0-9]"
                        required
                        prefix="$"
                        :error-messages="bidCapErrors"
                        class="styled-field dollar-field"
                        type="number" />
                </div>
            </div>
        </div>
        <!-- Pinterest video view objective only -->
        <div
            v-if="platform === PLATFORM_PINTEREST && showBidCap"
            class="mt-2 pr-4">
            Pinterest recommends <br> a $0.02 CPV
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { requiredRules } from '@/helpers/validationRules';
import { mapGetters, mapState } from 'vuex';
import { validatePlatformBudget, validateBidCap } from '@/components/ad-deployment/helpers/validatePlatformBudget.js';
import { DEPLOYMENT_PLATFORMS, CAMPAIGN_OBJECTIVES } from '@/components/ad-deployment/store/constants.js';
import { PLATFORM_PINTEREST } from '@/helpers/globals';

export default {
    components: {
        Icon
    },
    props: {
        platform: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            requiredRules,
            spend: null,
            selectedLevel: {
                ui_name: 'Campaign',
                value: 'campaign'
            },
            levels: [
                {
                    ui_name: 'Campaign',
                    value: 'campaign'
                },
                {
                    ui_name: 'Ad Set',
                    value: 'adSet'
                }
            ],
            PLATFORM_PINTEREST,
        };
    },
    computed: {
        ...mapState({
            ads: state => state.adDeployment.ads,
            budget: state => state.adDeployment.budget,
            schedule: state => state.adDeployment.budget.schedule,
            platforms: state => state.adDeployment.platforms,
        }),
        ...mapGetters('adDeployment', [
            'totalRunningDays'
        ]),
        platformBudgetInStore: {
            get() {
                return this.$store.state.adDeployment.budget.platform_specific[this.platform][this.selectedLevel.value][this.schedule === 'daily' ? 'daily_budget' : 'lifetime_budget'];
            },
            set(value) {
                this.$store.commit('adDeployment/setBudget', {
                    platform: this.platform,
                    level: this.selectedLevel.value,
                    schedule: this.schedule,
                    budget: value
                });
            }
        },
        bidCapInStore: {
            get() {
                return this.$store.state.adDeployment.budget.platform_specific[this.platform][this.selectedLevel.value].bid_cap;
            },
            set(value) {
                this.$store.commit('adDeployment/setBidCap', {
                    platform: this.platform,
                    level: this.selectedLevel.value,
                    bid_cap: value
                });
            }
        },
        budgetErrors() {
            const result = validatePlatformBudget({
                platform: this.platform,
                days: this.totalRunningDays,
                adDeploymentState: this.$store.state.adDeployment,
            });

            return result === true ? [] : [ result ];
        },
        bidCapErrors() {
            const result = validateBidCap({
                platform: this.platform,
                adDeploymentState: this.$store.state.adDeployment,
            });

            return result === true ? [] : [ result ];
        },
        campaignObjectiveBudget() {
            return CAMPAIGN_OBJECTIVES[this.platform].find(objective => objective.platform_value === this.$store.state.adDeployment.optimization.platform_specific[this.platform].objective)?.budget;
        },
        platformConfig() {
            return DEPLOYMENT_PLATFORMS.find(platform => platform.name === this.platform);
        },
        budgetLevels() {
            return this.campaignObjectiveBudget?.levels ?? this.platformConfig?.budget?.levels;
        },
        showBidCap() {
            return this.campaignObjectiveBudget?.bid_cap?.enabled;
        },
        selectableLevels() {
            return this.levels.filter(level => this.budgetLevels.includes(level.value));
        }
    },
    mounted() {
        // Select the first level as the default
        this.selectedLevel = this.selectableLevels[0];
        this.updateLevelForPlatform();
    },
    methods: {
        updateLevelForPlatform() {
            // Update the level for the platform in the store
            this.$store.commit('adDeployment/setBudgetLevel', {
                platform: this.platform,
                level: this.selectedLevel.value
            });

            // Clear out the budget for the level that was just deselected
            this.$store.commit('adDeployment/setBudget', {
                platform: this.platform,
                level: this.selectedLevel.value === 'campaign' ? 'adSet' : 'campaign',
                schedule: this.schedule,
                budget: null
            });
        }
    }
};
</script>


<style lang="scss" scoped>
.calc{
    color: #7C8187;
    font-size: 12px;
    padding-bottom: 30px;
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    &__head{
        padding: 0 40px;
        height:60px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #DFDFE0;
    }
    &__level{
        padding: 20px 0;
        padding-right: 40px;
        max-width: 175px;
    }
    &__spend{
        max-width: 200px;
    }
    &__footer{
        padding-top: 20px;
        margin: 20px 0px;
    }
}
.spend-wrapper{
    display: flex;
    width: 200px;
    margin-bottom: 10px;
}

.footer-spend-wrapper{
    display: flex;
    width: 200px;
}
.footer-cpv-text{
    width: 48px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 8px;
    margin-right: 7px;
}
.estimation-cpv {
    display: flex;
    transform: translateX(-15px);
}
.spend-input{
    max-width: 80px;
}
.spend-text{
    width: 48px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 8px;
    margin-right: 7px;
}
.snapchat-squad{
    padding: 8px 0;
    display: inline-block;
    white-space: nowrap;
}
.estimation {
    display: flex;
    transform: translateX(-25px);
}
.estimation-text {
    margin-top: 8px;
    margin-right: 20px;
}
.estimation-main {
    margin-top: 5px;
    font-size: 16px;
    color:#4e555b;
}
.estimation-dollar {
    display: inline-block;
    margin-right: 4px;
}
</style>
<style>
.dollar-field.v-input.v-text-field.v-input.v-text-field input {
    padding: 8px 0 8px 0px;
}
</style>