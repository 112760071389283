<template>
    <div>
        <div class="single-budgeting-wrapper">
            <div class="single-budgeting mr-3">
                <div class="single-budgeting__main">
                    <span class="single-budgeting__title">TYPE</span>
                    <span
                        v-if="schedule === 'daily'"
                        class="single-budgeting__name">Daily</span>
                    <span
                        v-else
                        class="single-budgeting__name">Lifetime</span>
                </div>
            </div>
            <div
                v-for="(platform, i) in platforms"
                :key="i"
                class="single-budgeting mr-3">
                <div class="single-budgeting__main">
                    <span class="single-budgeting__title">
                        <icon
                            :name="platform === 'facebook' ? 'meta' : platform"
                            color="#8F9EA6"
                            size="20" />
                    </span>
                    <span class="single-budgeting__name">
                        ${{ computeMoney(platform) }}/<span v-if="(schedule === 'daily')">day</span>
                        <span v-else>lifetime</span>
                    </span>
                </div>
            </div>
            <edit-button step="budgeting" />
        </div>
        <div class="single-budgeting-wrapper">
            <div class="single-budgeting">
                <div class="single-budgeting__main">
                    <span class="single-budgeting__title">START</span>
                    <span class="single-budgeting__name">
                        {{ startDate }}
                    </span>
                </div>
            </div>
            <div
                class="single-budgeting ml-4">
                <div class="single-budgeting__main">
                    <span class="single-budgeting__title">END</span>
                    <span class="single-budgeting__name">
                        {{ endDate }}
                    </span>
                </div>
            </div>
            <edit-button step="budgeting" />
        </div>
    </div>
</template>

<script>
import EditButton from '../../components/EditButton';
import Icon from '@/components/globals/Icon';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
    components: {
        EditButton,
        Icon
    },
    computed: {
        ...mapState({
            budget: state => state.adDeployment.budget,
            schedule: state => state.adDeployment.budget.schedule,
            platforms: state => state.adDeployment.platforms,
            storeStartDateTime: state => state.adDeployment.budget.start_date_time,
            storeEndDateTime: state => state.adDeployment.budget.end_date_time,
        }),
        startDate() {
            if (this.schedule === 'daily'
                && !this.storeStartDateTime) {
                return 'Immediately';
            }

            return this.beautifyDate(this.storeStartDateTime);
        },
        endDate() {
            if (this.schedule === 'daily'
                && !this.storeEndDateTime) {
                return 'Indefinitely';
            }

            return this.beautifyDate(this.storeEndDateTime);
        }
    },
    methods: {
        computeMoney(platform) {
            const level = this.$store.state.adDeployment.budget.platform_specific[platform].level;
            const type = this.schedule === 'daily' ? 'daily_budget' : 'lifetime_budget';
            return this.$store.state.adDeployment.budget.platform_specific[platform][level][type];
        },
        beautifyDate(date) {
            return moment(date).format('M/D/YY h:mma');
        },
    }
};
</script>

<style lang="scss" scoped>
.single-budgeting-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}
.single-budgeting{
    max-width: calc(100% - 100px);
    display: inline-block;
    background: white;
    padding: 10px 45px 10px 15px;
    border: 1px solid #DFDFE0;
    &__bottom{
        margin-top: 12px;
        position: relative;
        padding-left: 30px;
        &:after{
            content: '';
            position: absolute;
            width: 20px;
            height: 1px;
            background: #999999;
            top: 10px;
            left: 5px;
        }
        &:before{
            content: '';
            position: absolute;
            width: 1px;
            height: 15px;
            background: #999999;
            top: -5px;
            left: 5px;
        }
    }
    &__title{
        font-weight: 700;
        margin-right: 15px;
    }
    &__name{
        color: #03A2EA;
        font-style: italic;
    }
    &.multiple{
        position: relative;
        padding-left: 60px;
        .icon{
            position: absolute;
            top:10px;
            left: 15px;
        }
    }
}
</style>